<template>
  <div>
    <v-dialog width="400" v-model="dialog.flag">
      <v-card>
        <v-card-title class="text-h6 primary_2 py-2 white--text">
          {{ dialog.title }}
          <v-spacer></v-spacer>
          <v-btn @click="dialog.flag = false" fab text small>
            <v-icon large color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3 pb-2 font-weight-bold text-body-2">
          {{ dialog.message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="success"
            class="white--text font-weight-bold"
            @click="resetKyc()"
            :disabled="reset_kyc_btn_loader"
            :loading="reset_kyc_btn_loader"
          >
            Yes
          </v-btn>
          <v-btn
            small
            color="red"
            class="white--text font-weight-bold"
            :disabled="reset_kyc_btn_loader"
            :loading="reset_kyc_btn_loader"
            @click="dialog.flag = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      reset_kyc_btn_loader: false,
    };
  },
  methods: {
    resetKyc() {
      const self = this;

      self.reset_kyc_btn_loader = true;

      let form = new FormData();
      form.append("customer_id", self.decrypt(self.$route.params.id));

      const successHandler = (response) => {
        self.reset_kyc_btn_loader = false;
        self.dialog.flag = false;
        self.$emit("onSuccess");
      };
      const failureHandler = () => {
        self.reset_kyc_btn_loader = false;
      };
      const finallyHandler = () => {};
      self.POST_request(
        self,
        self.$urls.RESET_KYC,
        form,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
  },
};
</script>
